<template>
  <div class="flex gap-x-2">
    <template v-for="(_, index) in length">
      <input
        :key="index"
        :ref="index"
        :autofocus="index === 0"
        type="tel"
        maxlength="1"
        class="border border-gray-400 rounded w-10 h-12 text-center appearance-none"
        :class="{ 'border-error': errorMessages.length > 0 }"
        @input="onCodeInput"
        @paste="onCodePaste"
        @keydown.backspace="$event.target.value || onCodeBackspace(index)"
      />
    </template>
  </div>
</template>

<script lang="js">
export default {
  name: 'PhoneVerificationFormPart',

  props: {
    length: {
      type: Number,
      required: true,
    },
    errorMessages: {
      type: Array,
      required: true,
    },
  },

  /**
   * @returns {{value: string}}
   */
  data() {
    return {
      value: '',
    }
  },

  methods: {
    /**
     * @param {Event} event
     * @param {Element} event.target
     */
    onCodeInput({ target }) {
      // Check if input is a valid number
      if (isNaN(target.value)) {
        target.value = ''
        return
      }

      // Recreate the value
      this.value = Array.from(Array(this.length), (element, i) => {
        return this.$refs[i][0].value || ''
      }).join('')

      this.emitValue()

      // Go to next input if possible
      if (target.nextElementSibling && target.value) {
        target.nextElementSibling.focus()
        target.nextElementSibling.select()
      }
    },

    /**
     * @param {Event} event
     * @param {object} event.clipboardData
     */
    onCodePaste({ clipboardData }) {
      const paste = clipboardData.getData('text')

      // Check if given code in clipboard is valid
      if (!paste.match(/^\d{6}$/)) {
        return
      }

      // Update value
      this.value = paste
      this.emitValue()

      const inputs = Array.from(Array(this.length))
      inputs.forEach((_, i) => {
        this.$refs[i][0].value = paste[i].toString()
      })
    },

    /**
     * @param {string} currentIndex
     */
    onCodeBackspace(currentIndex) {
      const previousIndex = parseInt(currentIndex) - 1
      if (this.$refs[previousIndex]) {
        this.value = this.value.slice(0, -1)
        this.emitValue()
        this.$refs[previousIndex][0].focus()
      }
    },

    /**
     * Emits the value of the code fields
     */
    emitValue() {
      this.$emit('update-value', this.value)
    },
  },
}
</script>
