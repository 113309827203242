<template>
  <FormModal class="form-modal--verify-phone">
    <template #body>
      <NavigationBar />

      <PhoneVerifyFormStep
        ref="phoneVerify"
        :phone-number="phoneNumber"
        :progress-value="progressValue"
      />
    </template>

    <template #footer>
      <div />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import NavigationBar from 'chimera/all/themes/blueflow/components/layout/NavigationBar'
import PhoneVerifyFormStep from '~/components/form/steps/phoneVerify/PhoneVerifyFormStep'

export default {
  components: {
    NavigationBar,
    PhoneVerifyFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Controlla il tuo telefono',
      headDescription:
        'Inserisci i tuoi dati per metterci in contatto con lo specialista più adatto nella tua zona.',
      path: '/richieste-di-preventivi/controlla-il-tuo-telefono',
      progressValue: 95,
    }
  },

  computed: {
    /**
     * @returns {*}
     */
    phoneNumber() {
      return this.$store.getters['lead/getData']('phone', '')
    },
  },

  /**
   */
  beforeMount() {
    this.setCheckoutStep(6)
  },
}
</script>

<style lang="scss">
.form-modal--verify-phone {
  @apply bg-white;
  .form-modal {
    &__progress {
      @apply hidden;
    }

    &__title {
      @apply leading-9 text-xl mt-6;
    }

    &__subtitle {
      @apply text-center sm:px-20 leading-6 mt-5;
    }

    &__body {
      @apply bg-white px-0;
    }

    &__footer {
      @apply hidden;
    }
  }
}
</style>
